import React from "react";

function Uni() {
  return (
    <div className=" px-5 max-w-[1560px] mx-auto pt-5 py-10">
      {/* title */}
      <div className=" mb-5">
        <div className=" text-white w-2/3 font-medium text-[32px]">
          <div className="">
            <span className="text-[#C778DD]">#</span>education
          </div>
        </div>
      </div>
      <div className=" items-center justify-between">
        {/* left */}
        <div className="inline-flex mb-3">
          <div className="md:w-[57%] w-full flex flex-wrap">
            <div className="text-white text-base font-normal">
              California State University, Bakersfield
            </div>
            {/* disc */}
            <p className="text-[#ABB2BF] ">
              Bachelor's of Science, Computer Science &lt;2024 /&gt;
            </p>
          </div>
          <a
            href="https://www.csub.edu/"
            title="CSUB homepage"
            target="_blank"
            rel="noreferrer"
          >
            <img className="mx-auto" src={require("./img/runna.png")} alt="" />
          </a>
        </div>
        <div className="inline-flex">
          <div className="md:w-[60%] w-full flex flex-wrap">
            <div className="text-white text-base font-normal">
              University of California, Irvine
            </div>
            {/* disc */}
            <p className="text-[#ABB2BF] ">
              Bachelor's of Science, Biological Sciences &lt;2015 /&gt;
            </p>
          </div>
          <a
            href="https://uci.edu/"
            title="UCI homepage"
            target="_blank"
            rel="noreferrer"
          >
            <img className="mx-auto" src={require("./img/zot.png")} alt="" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Uni;
